<template>
  <div class="content" ref="content">
    <div class="main-content">
      <a-spin tip="Loading..." :spinning="loading">
        <table class="table">
          <colgroup>
            <col width="50" />
            <col width="300" />
            <col />
            <col width="200" />
          </colgroup>
          <thead>
            <tr>
              <th>序号</th>
              <th>标题</th>
              <th>状态/数值</th>
              <th>标识</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(v, k) in list" :key="k">
              <td>{{ k + 1 }}</td>
              <td>{{ v.title }}</td>
              <td>
                <template v-if="v.type === 1">
                  <a-input style="max-width: 600px" v-model:value="v.value" />
                </template>
                <template v-if="v.type === 2">
                  <a-input v-model:value="v.value" style="width: 200px" />
                </template>
                <template v-if="v.type === 3">
                  <a-textarea v-model:value="v.value" :rows="4" />
                </template>
                <template v-if="v.type === 4">
                  <a-switch v-model:checked="v.value" />
                </template>
                <template v-if="v.type === 5">
                  <e-input-upload title="竖版Logo" v-model:value="v.value" />
                </template>
                <div v-if="v.remark" class="desc">{{ v.remark }}</div>
              </td>
              <td>{{ v.name }}</td>
            </tr>
          </tbody>
        </table>
      </a-spin>

      <div class="form-footer">
        <a-button
          @click="onSubmitFun"
          :loading="loading2"
          size="large"
          type="primary"
        >
          提交
        </a-button>
        <a-button @click="onGetConfigs" size="large" style="margin-left: 15px">
          刷新
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
import { ConfigClass } from '@/apis/config'
import { message } from 'ant-design-vue'
import { defineComponent, ref } from 'vue'

const api = new ConfigClass()
export default defineComponent({
  setup () {
    const loading = ref(false)
    const loading2 = ref(false)
    const list = ref([])

    const onGetConfigs = () => {
      loading.value = true
      api.gets().then((resp) => {
        list.value = resp
        loading.value = false
      })
    }

    const onSubmitFun = () => {
      loading2.value = true
      api.update(list.value).then(() => {
        message.success('修改成功')
        loading2.value = false
      })
    }

    onGetConfigs()
    return {
      loading,
      list,
      loading2,

      onGetConfigs,
      onSubmitFun
    }
  },

  created () {}
})
// export default {
//   mixins: [form, baseMixin],
//   components: {
//     FooterToolBar,
//     ConfigForm
//   },
//   data() {
//     return {
//       labelCol: { span: 4 },
//       wrapperCol: { span: 14 },
//       loading: true,
//       configs: [],
//       action: '',
//       tabTitle: ['系统设置', '公众号设置', '小程序设置']
//     }
//   },
//   created() {},
//   mounted() {
//     this.getConfigs()
//     this.action = Setting.upload_url + '/api/Resource/AddExt'
//   },
//   computed: {},
//   methods: {
//     handleChange(k, index, info) {
//       if (info.file.status !== 'uploading') {
//         console.log(info.file, info.fileList)
//       }
//       if (info.file.status === 'done') {
//         this.$message.success(`${info.file.name} 上传成功`)
//         this.configs[k][index].value = info.file.response.url
//       } else if (info.file.status === 'error') {
//         this.$message.error(`${info.file.name} 上传失败.`)
//       }
//     },
//     submitForm() {
//       this.loading = true
//       const configs = []
//       this.configs.forEach(e => {
//         configs.push(...e)
//       })

//       Config.save(configs).then(() => {
//         this.$message.success('编辑成功')
//         this.getConfigs()
//       })
//     },

//     changeSwitch(row, e) {
//       row.value = e + ''
//     },
//     changeInputNumber(row, e) {
//       row.value = e + ''
//     },

//     create(e) {
//       this.loading = true
//       Config.add(e).then(() => {
//         this.$message.success('添加成功')
//         this.getConfigs()
//       })
//     },
//     add() {
//       this.$refs.configForm.open(null)
//     },

//     getConfigs() {
//       Config.gets().then(resp => {
//         this.formatConfigs(resp)
//         this.loading = false
//       })
//     },
//     formatConfigs(data) {
//       const len = data.length
//       if (len > 0) {
//         const group = []
//         for (let i = 0; i < len; i++) {
//           if (group.indexOf(data[i].group) === -1) {
//             group.push(data[i].group)
//           }
//         }
//         const len2 = group.length
//         const configs = []
//         for (let j = 0; j < len2; j++) {
//           const items = []
//           for (let i = 0; i < len; i++) {
//             if (group[j] === data[i].group) {
//               items.push(data[i])
//             }
//           }
//           configs[j] = items
//         }

//         console.log(configs)
//         this.configs = configs
//       }
//     },

//     callback(e) {
//       console.log(e)
//     },

//     refresh() {
//       this.loading = true
//       this.getConfigs()
//     }
//   }
// }
</script>

<style lang="less" scoped>
.table {
  width: 100%;
  thead {
    tr {
      th {
        padding: 5px 10px;
        background: #f5f5f5;
        height: 40px;
        line-height: 40px;
      }
    }
  }
  tbody {
    tr {
      td {
        padding: 5px 10px;
        height: 60px;
        border-bottom: 1px solid #f1f1f1;
        .desc {
          height: 24px;
          line-height: 24px;
          font-size: 12px;
          color: #f00;
        }
      }
    }
  }
}

.form-footer {
  position: absolute;
  bottom: 9px;
  left: 0;
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  background: #fff;
  box-shadow: 0 -5px 5px rgba(0, 0, 0, 0.1);
  z-index: 10003;
  border-radius: 0 0 15px 15px;
  padding: 0 20px;
}
</style>
